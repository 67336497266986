@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* author fonts */

@font-face {
  font-family: 'AuthorBold';
  src: local('AuthorBold'),
    url('./assets/fonts/Author/Author-Bold.otf') format('opentype'),
}

@font-face {
  font-family: 'Author';
  src: local('Author'),
    url('./assets/fonts/Author/Author-Regular.otf') format('opentype'),
}

@font-face {
  font-family: 'AuthorMedium';
  src: local('AuthorMedium'),
    url('./assets/fonts/Author/Author-Medium.otf') format('opentype'),
}

@font-face {
  font-family: 'AuthorSemibold';
  src: local('AuthorSemibold'),
    url('./assets/fonts/Author/Author-Semibold.otf') format('opentype'),
}

/* helvitaca fonts */
@font-face {
  font-family: 'helveticaBold';
  src: local('helveticaBold'),
    url('./assets/fonts/Helvetica/Helvetica-Bold.ttf') format('opentype'),
}

@font-face {
  font-family: 'helveticaBoldOblique';
  src: local('helveticaBoldOblique'),
    url('./assets/fonts/Helvetica/Helvetica-BoldOblique.ttf') format('opentype'),
}

@font-face {
  font-family: 'helveticaCompressed';
  src: local('helveticaCompressed'),
    url('./assets/fonts/Helvetica/helvetica-compressed-5871d14b6903a.otf') format('opentype'),
}

@font-face {
  font-family: 'helveticaOblique';
  src: local('helveticaOblique'),
    url('./assets/fonts/Helvetica/Helvetica-Oblique.ttf') format('opentype'),
}

@font-face {
  font-family: 'helvetica';
  src: local('helvetica'),
    url('./assets/fonts/Helvetica/Helvetica.ttf') format('opentype'),
}

.author {
  font-family: "Author";
}

.helvetica {
  font-family: "helvetica";
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3 !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}